<template>
    <base-modal
        close
        :show="show"
        size="md"
        :loading="state.loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('accountantClients.documents.uploadModal.title') }}
            </h5>
        </template>
        <div class="flex flex-col">
            <form-file-upload
                v-model="files"
                :description="
                    $t('accountantClients.documents.uploadModal.description')
                "
                :accepted-files="[
                    '.jpg',
                    '.jpeg',
                    '.png',
                    '.pdf',
                    '.gif',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx',
                ]"
            />
            <div class="">
                <h5 class="mb-2 text-sm font-medium text-gray-700">
                    {{
                        $t(
                            'accountantClients.documents.uploadModal.fileRestriction'
                        )
                    }}
                </h5>
                <ul class="flex flex-col gap-2.5 py-2.5">
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t(
                                    'accountantClients.documents.uploadModal.maxFileSize',
                                    {
                                        size: 5,
                                    }
                                )
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t(
                                    'accountantClients.documents.uploadModal.allowFile',
                                    {
                                        files: allowFiles.join(', '),
                                    }
                                )
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t(
                                    'accountantClients.documents.uploadModal.fileNameContain'
                                )
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t(
                                    'accountantClients.documents.uploadModal.fileNameMustContain'
                                )
                            }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="state.loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    :disabled="!hasFiles"
                    :loading="state.loading"
                    @click="onUpload"
                >
                    {{ $t('general.upload') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
import { isEmpty } from 'lodash-es'

const emit = defineEmits(['on-finished-upload', 'modal-close'])

const props = defineProps({
    tenantId: {
        type: String,
        required: true,
    },
    show: {
        type: Boolean,
        default: false,
    },
    parentId: {
        type: String,
        required: true,
    },
})

const { t } = useI18n()
const { errorNotify } = useNotification()
const allowFiles = ['JPG', 'PNG', 'PDF', 'GIF', 'DOC', 'DOCX', 'XLS', 'XLSX']

const files = ref([])

const hasFiles = computed(() => !isEmpty(unref(files)))

const state = reactive({
    loading: false,
})

const { uploadMultiples } = useUploadStorage()
const { execute } = useApi('/api/accountant-documents/files', 'POST')

const closeModal = () => {
    emit('modal-close')
}

const onUpload = async () => {
    try {
        state.loading = true
        const uploadKeys = await uploadMultiples(unref(files))

        await execute({
            data: {
                id: props.parentId,
                files: uploadKeys,
            },
            headers: {
                tenant: props.tenantId,
            },
        })

        emit('on-finished-upload')
    } catch ({ errorMessage }) {
        errorNotify({
            title: t('accountantClients.documents.uploadModal.uploadError'),
            text: errorMessage,
        })
    } finally {
        state.loading = false
    }
}
</script>
